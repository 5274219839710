<template>
  <v-container class="container-max-width">
    <v-row v-if="isLoading" id="syncio-loading-logo">
      <v-col class="text-center">
        <loading-syncio-logo></loading-syncio-logo>
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col>
        <v-row>
          <v-col cols="12" class="align-center">
            <div class="text-center relative">
              <!-- <v-icon size="40" @click="$router.go(-1)" class="absolute go-back-link">
                chevron_left
              </v-icon>  -->
              <svg width="120px" height="30px" viewBox="0 0 100 30" style="transform: translateX(-6px);">
                <circle cx="10" cy="10" r="9" class="filled"/>
                <line x1="19" y1="10" x2="31" y2="10" class="line"/>
                <circle cx="40" cy="10" r="9" class="filled"/>
                <line x1="61" y1="10" x2="49" y2="10" class="line"/>
                <circle cx="70" cy="10" r="9" class="not-filled"/>
                <line x1="91" y1="10" x2="79" y2="10" class="line"/>
                <circle cx="100" cy="10" r="9" class="not-filled"/>
              </svg>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="text-center">
            <p class="text-h8 text-center mb-2 font-weight-bold font-primary">STEP 2</p>
            <div class="text-h1 primary--text">
              Connect to your first source store!
            </div>
          </v-col>
        </v-row>
        <v-row class="mt-4">
          <v-col>
            <v-card class="pa-8 card-rounded not-a-link">
              <v-row>
                <v-col class="text-center key-color">
                  <div class="text--label--1">
                    Your store’s unique key:
                  </div>
                  <div class="text-subtitle-1">
                    {{ this.currentShop.identifier }}
                  </div>
                </v-col>
              </v-row>
              <v-row class="mt-4">
                <v-col class="text-center">
                  <div class="text-body-2">
                    Share your unique key with source stores to start importing their products to sell on your store. This unique key can also be found on your dashboard after completing this setup process.
                  </div>
                </v-col>
              </v-row>
              <v-row class="mt-4 mb-6">
                <v-col class="d-flex flex-row justify-space-between">
                  <v-btn class="primary" width="47.5%" height="48px" @click="$router.push('/shopify/connect-and-invite-store/invite-store')">
                    <div class="text-body-1">
                      Share your key via e-mail
                      <v-icon size="20" right>
                        arrow_forward
                      </v-icon>
                    </div>
                  </v-btn>
                  <v-btn outlined class="primary-outline received-button-border" width="47.5%" height="48px" @click="$router.push('/shopify/connect-and-invite-store/connect-store')">
                    <div class="text-body-1">
                      I received a source store key
                      <v-icon size="20" right>
                        arrow_forward
                      </v-icon>
                    </div>
                  </v-btn>
                </v-col>
              </v-row>
              <v-row v-if="isConnect==false">
                <v-col class="text-center">
                  <div class="spacer"></div>
                  <div class="text-body-2 pt-8">
                    Haven’t received a unique key? <br>
                    Why not try out our awesome features with our test store instead?
                  </div>
                  <div class="mt-5">
                    <v-btn outlined class="primary-outline received-button-border font-weight-bold" width="250px" height="48px" @click="connectDemoStoreWithKey">
                      <div class="text-body-1">
                        Connect to Demo Store
                      </div>
                      <div class="ml-2">
                        <v-icon size="20" right>
                          add
                        </v-icon>
                      </div>
                    </v-btn>
                  </div>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
        <v-row class="mt-7">
          <v-col class="text-center">
            <div class="text-body-2">
              <router-link class="text-decoration-none font-weight-bold" to="/shopify/select-plan">Skip for now.</router-link>
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {mapGetters} from "vuex";
import LoadingSyncioLogo from "@/views/registration/components/LoadingSyncioLogo";

export default {
name: "ConnectAndInviteStore",
  components: {LoadingSyncioLogo},
  data() {
    return {
      uniqueKey:"",
      isConnect: false
    }
  },
  created() {
    this.$store.dispatch(`shop/loadCurrentShop`)
  },
  computed: {
    ...mapGetters("shop", ["currentShop", "isLoading"]),
  },
  methods: {
    connectDemoStoreWithKey(){
        this.$store.dispatch(`shop/connectStoreWithKey`, {shopId: this.currentShop.id, key: '5d49553267519'}).then(() => {
          this.isConnect = true;
          // may be redirect the user to dashboard  or make the user stay at same place 
          // hardcoded the store key ask - team if this need to be in config 
        });
    }
  }
}
</script>

<style scoped>
.container-max-width{
  max-width: 700px !important;
}

.key-color{
  color: #FA757B;
}

.received-button-border{
  border: 2px solid #0E3B4D;
  box-sizing: border-box;
  border-radius: 10px;
}

.spacer {
  border-top: solid 1px #C7C7C7;
}

#syncio-loading-logo {
  left: 50%;
  position: fixed;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
}
</style>
